import React from 'react';
import ArticleContent from '../components/ArticleContent';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ArticlePage = () => {
  return (
    
        <div className="home-galerie">
      <div className="home-container">
         <Helmet>
        {/* ... vos métadonnées ... */}
      </Helmet>

      <Header />
      <ArticleContent />
      </div>
     
      <Footer />
    </div>

  );
};

export default ArticlePage;