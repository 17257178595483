import React from 'react'; 
import { Link } from 'react-router-dom';

import '../css-styles/Title.scss';

const Title = () => {
    return (
        <div className='title-wrapper'>
            <h1 className='title-main'>Diwali Yoga</h1>
            <Link to="/Pratiquer"><button>Prendre un cours</button></Link>
        </div>
    );
}

export default Title;