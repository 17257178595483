import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageKunda from '../images/icone/kundalini yoga.png';
import ImageKundaGr from '../images/icone/kundalini yoga GR.png';
import ImageTantra from '../images/icone/tantra yoga.png';
import ImageTantraGr from '../images/icone/tantra yoga GR.png';
import ImageSavita from '../images/icone/savita yoga.png';
import ImageSavitaGr from '../images/icone/savita yoga GR.png';
import ImageTherapy from '../images/icone/therapy holistique.png';
import ImageTherapyGr from '../images/icone/therapy holistique GR.png';
import '../css-styles/Activites.scss';

const Activites = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  return (
    <div className='designer-wrapper'>
      <h2 className='element-title'>Les activités</h2>
      <div className='icone-container'>

        <div className='icone-text'>
          <h3>Kundalini yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'kundalini' ? ImageKundaGr : ImageKunda} 
            onMouseEnter={() => setHoveredIcon('kundalini')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Kundalini Yoga Icon"
          />
          <p>Le Kundalini Yoga stimule l'énergie vitale par des postures, des respirations et des chants de mantras.</p> 
          <p>Il équilibre le système nerveux et favorise la santé mentale avec des exercices dynamiques et la méditation.</p>
          <Link to="/kundalini-yoga"><button className="btn-more">En savoir plus</button></Link>
        </div>

        <div className='icone-text'>
          <h3>Tantra yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'tantra' ? ImageTantraGr : ImageTantra} 
            onMouseEnter={() => setHoveredIcon('tantra')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Tantra Yoga Icon"
          />
          <p>Le Tantra Yoga se concentre sur les mantras et alterne relaxation, méditation et mouvements. </p>
          <p>Il permet la circulation de l'énergie et calme l'esprit.</p>
          <Link to="/tantra-yoga"><button className="btn-more">En savoir plus</button></Link>
        </div>

        <div className='icone-text'>
          <h3>Savita yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'savita' ? ImageSavitaGr : ImageSavita} 
            onMouseEnter={() => setHoveredIcon('savita')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Savita Yoga Icon"
          />
          <p>Le Savita Yoga, doux et apaisant, réduit le stress avec des postures simples, une respiration consciente et une relaxation profonde.</p>
          <Link to="/savita-yoga"><button className="btn-more">En savoir plus</button></Link>
        </div>

        <div className='icone-text'>
          <h3>Holistic therapy</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'therapy' ? ImageTherapyGr : ImageTherapy} 
            onMouseEnter={() => setHoveredIcon('therapy')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Holistic Therapy Icon"
          />
          <p>La thérapie holistique harmonise le corps et l'esprit à travers des soins énergétiques, la méditation et la visualisation pour rétablir l'équilibre global.</p>
          <Link to="/holistic-therapy"><button className="btn-more">En savoir plus</button></Link>
        </div>

      </div>
    </div>
  );
}

export default Activites;