import React, { useEffect, useState } from "react";
import "../css-styles/Niveaux.scss";

const Niveaux = () => {

  return (
    <div className="niveau-wrapper">
        <h2>Les quatre niveau de l'être</h2>
        <div className="title-text-niveau">
            
           <div className="element-niveau">
                <h3>Niveau physique</h3>
                <p>Amélioration du bien-être corporel, avec plus de souplesse, d'amplitude dans les articulations, une meilleure posture, et la libération des blocages corporels.</p>
            </div> 
            <div className="element-niveau">
                <h3>Niveau mental</h3>
                <p> Développement de la concentration, de la sérénité mentale et de la capacité à méditer.</p>
            </div>
            <div className="element-niveau">
                <h3>Niveau énergétique</h3>
                <p>Augmentation de l'énergie vitale, meilleure gestion du stress, plus d'endurance et un rayonnement intérieur renforcé.</p>
            </div>
            <div className="element-niveau">
                <h3>Niveau subtil (spirituel)</h3>
                <p>Meilleure connaissance de soi, alignement intérieur, plus d'empathie et ouverture du cœur.</p>
            </div>
        </div>
    </div>
  );
};

export default Niveaux;