import React from 'react';
import '../css-styles/Professeur.scss';
import Header from "../components/Header";
import Portrait from "../components/Portrait";


const Professeur = () => {
  return (
    <div className="home" >
      <div className="home-container">
        <Header />
        <Portrait />
    
      </div>
    </div>
  );
}

export default Professeur;