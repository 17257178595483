import React from 'react';
import '../css-styles/PratiquerLeYoga.scss';
import Header from "../components/Header";
import Image1 from "../images/Portraits/portraitAnne.jpg"
import Image2 from "../images/Portraits/portraitMarie.jpg"


const Portrait = () => {
  return (
    <div className="Portrait-wrapper" >
      
       <div className='Portrait-element'>
        <img className='Portrait-image' src={Image1}/>
        <div className='Portrait-text'>
            <h2>Anne-Laure</h2>
            <p>Je m’appelle Anne-Laure, j’ai bientôt 58 ans et je suis professeure de Kundalini Yoga depuis 2017 après avoir effectué une reconversion professionnelle</p>
            <p>J’ai pratiqué ce yoga depuis les années 2000 en dilettante et un jour j’ai décidé de plonger dans l’athanor du Château Laroque. C’était une évidence et le rêve s’est concrétisé assez rapidement</p>
            <p>Aujourd’hui je suis très heureuse de faire ce que j’aime et de mettre mes compétences diverses et variées au service de l’apprentissage du yoga et de l’aide en accompagnement à la personne</p>
            <p>Animatrice de cours et de stages de Kundalini, j’adore amener le groupe vers une amélioration corporelle, mentale et subtile. C’est cette quête d’un mieux-être qui me motive toujours plus.</p>
        </div>
       </div>
       <div className='Portrait-element'>
        <img className='Portrait-image' src={Image2}/>
        <div className='Portrait-text'>
            <h2>Marie</h2>
            <p>Je suis Marie Eypper, pratiquante en yoga depuis 2006. Cette pratique s'est très vite transformée en passion, que je cultive au quotidien.</p>
            <p>J’ai tout de suite particulièrement aimé la diversité dans le yoga. J’ai suivi plusieurs formations pour m’imprégner de différents yoga</p>
            <p>Aujourd’hui je focalise mon attention sur 2 yogas: le Savita Yoga et le Kundalini Yoga. J'enseigne le Savita yoga depuis 2018 et le yoga est mon activité principale depuis 2022.</p>
        </div>
       </div>
    </div>
  );
}

export default Portrait;