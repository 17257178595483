import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css-styles/Hero.scss';
import { PrevArrow, NextArrow } from './SliderArrows';  
import Title from './Title';
import Header from "../components/Header";

import Image1 from '../images/images aryana/Oriental photo.webp';
import Image1Small from '../images/images aryana/Oriental photo.webp';




const Hero = () => {
  
  return (
    <div className='slider-wrapper'>
        <div>
        <img src={window.innerWidth <= 576 ? Image1Small : Image1} className="slider-photographie" alt="photographie-couple-mariage-dolcevita" loading="eager" />
        
        <Title />
        </div>   
    </div>
  );
}

export default Hero;