import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css-styles/NavigationBar.scss';
import logo from '../images/icone/Diwali Yoga.png';

const NavigationBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`nav-bar ${isScrolled ? 'scrolled' : ''}`}>
      <div className={`logo ${isScrolled ? 'hide' : ''}`}>
        <img src={logo} alt="Diwali Yoga logo" />
      </div>

      <button className="menu-button" onClick={toggleMenu}>
        Menu
      </button>

      <ul ref={menuRef} className={`main-menu ${menuOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" className={location.pathname === "/" ? "active" : ""}>Accueil</Link>
        </li>
        <li>
          <Link to="/La-voie-du-Tantra" className={location.pathname === "/La-voie-du-Tantra" ? "active" : ""}>La voie du Tantra</Link>
        </li>
        <li>
          <Link to="/Pratiquer" className={location.pathname === "/Pratiquer" ? "active" : ""}>Pratiquer</Link>
        </li>
        <li>
          <Link to="/Professeur" className={location.pathname === "/Professeur" ? "active" : ""}>Professeur</Link>
        </li>
        <li>
          <Link to="/Apropos" className={location.pathname === "/Apropos" ? "active" : ""}>A propos</Link>
        </li>
        <li>
          <Link to="/Blog" className={location.pathname === "/Blog" ? "active" : ""}>Blog</Link>
        </li>
        <li>
          <Link to="/Contact" className={location.pathname === "/Contact" ? "active" : ""}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;