import React from 'react';
import Header from '../components/Header';
import '../css-styles/RealisationTantra.scss';
import Footer from '../components/Footer';
import Imagekun from '../images/images aryana/abstract, minimalist illustration of a meditative figure.webp';

import ImageStages from '../images/images aryana/schema.png'; // Example Image for Eight Stages of Yoga
import ImageYogaPractice from '../images/images aryana/peaceful illustration representing the practice of yoga.webp'; // Example Image for Yoga Practice

import ImageMaAnanda from '../images/Pages/Mâ Ananda Moyi.jpg';
import Image4 from '../images/images aryana/kundalini cours 2.webp';
import Image5 from '../images/images aryana/kundalini_cours_3.webp';

import { FaHeart, FaSeedling, FaUsers } from 'react-icons/fa';

const RealisationTantra = () => {
  return (
    <div className='global-text-wrapper'>
      <h1>Le Tantra « Blanc » quintessence du Kundalini</h1>
      <img src={ImageMaAnanda} className='img-maananda'/>
      <blockquote>« Notre corps est aussi un corps vibratoire. Ce corps est comme un instrument de musique. <br>
      </br>Ce que vous entendez dépend de votre façon de jouer ! » -<strong>Mâ Ananda Moyi</strong> </blockquote>
      <div className='text-wrapper'>
        <p className='up-down-text'>Le Tantra Blanc est une forme spécifique de pratique tantrique qui se concentre sur la purification de l'esprit et l'élévation de la conscience. Contrairement aux formes plus intimes du tantra, comme le tantra rouge, le tantra blanc n'implique pas d'actes sexuels. Il s'agit d'une pratique méditative souvent réalisée en groupe, avec des exercices de respiration, des postures de yoga, des mantras et des méditations guidées.</p>
        <p className='up-down-text'>L'objectif du Tantra Blanc est d'éliminer les blocages mentaux et émotionnels, de nettoyer le subconscient et d'élever l'énergie spirituelle pour favoriser la clarté mentale, la paix intérieure et une connexion plus profonde avec soi-même et le monde. Cette pratique est souvent associée au Kundalini Yoga, introduit par Yogi Bhajan en Occident, et se déroule généralement lors de grandes méditations collectives.</p>
        <h2>Le Kundalini Yoga</h2>
        <div className='text-image-wrapper'>
          <div>
            <p className='center-text'>Il permet de changer et de renforcer son rayonnement pour que la vie prenne toute son expansion et que nos capacités puissent s’épanouir.</p>
            <p className='center-text'>Le Kundalini Yoga est une pratique spirituelle et physique visant à éveiller l'énergie vitale à travers des postures, des techniques de respiration, des chants de mantras, et des méditations.</p>
            <p className='center-text'>Il équilibre les systèmes nerveux et énergétiques, favorisant la santé mentale et la conscience spirituelle. Une séance inclut des exercices dynamiques, une relaxation guidée, et une méditation.</p>
          </div>
          <div className="image-wrapper">
            <img src={Imagekun} alt="Kundalini Rising" />
          </div>
        </div>
        
        <h2>Le Tantra Yoga</h2>
        
        <div className='module-text-wrapper'>
            <p>C’est le Kundalini Yoga avec le parfum de la méditation. Il se décompose en 5 phases : Le kriya court, les mantras, la relaxation, la méditation chantée, et le silence intérieur.  
          Dans ce Yoga la science des mantras va prendre toute son importance. Sur un mantra particulier se déclinent les 4 phases Danse-relax-méditation-méditation silencieuse.</p>
          <p>Cette alternance d’états physiques (dynamique ou posé, ralenti et méditatif, en action ou immobile), va permettre la circulation de l'énergie, puis une détente, une sensation d’harmonie et de douceur afin de rentrer dans son propre silence intérieur.
          Enfin le mental va ralentir. Le Kriya, lui, sera écourté, mais ce seront les mêmes exercices que dans un cours classique.
          </p>
        </div>
        </div>
    </div>
  );
}

export default RealisationTantra;