import React from 'react';
import '../css-styles/PratiquerLeYoga.scss';
import Header from "../components/Header";
import CalendarPage from '../components/CalendarPage';
import ImageSalleGuen1 from '../images/images aryana/Guenrouet Salle1.jpg';
import ImageSalleGuen2 from '../images/images aryana/salle culturelle guenrouet al.png';
import ImageSalleGuen3 from '../images/images aryana/salle culturelle guenrouet al 2.png';
import ImageSalleBreteil1 from '../images/images aryana/breteilSalle1.jpeg';
import ImageSalleBreteil2 from '../images/images aryana/breteilSalle2.jpeg';
import ImageSalleBreteil3 from '../images/images aryana/Carine Yoga.jpeg';



const PratiquerLeYoga = () => {
  return (
    <div className="home" >
      <div className="home-container">
        <Header />
        <div className="yoga-page-container">
          <h1>Pratiquer le Yoga</h1>

          <section className='presentation-cours'>
            <h2>Présentation des cours</h2>
            <div className='presentation-texte'>
             <p>
              Si le plan mental est mis en avant, il ne faut pas négliger le caractère physique du kundalini et ses effets sur le corps. On bouge beaucoup pour, justement, réveiller l’énergie: rapidement, la silhouette va se modifier au fil des séances.
              </p>
              <p>
                Chaque leçon (entre 1 heure et 1 h 30) est différente, thématique (renforcer son système immunitaire, renforcer sa colonne vertébrale, harmoniser les centres d’énergie, etc.) et minutée 3 minutes, 5 minutes. Elle commence par quelques mouvements  pour échauffer le corps, puis se concentre sur les kriyas, des postures réalisées les yeux fermés - la plupart du temps - qui mobilisent la colonne vertébrale, les jambes, les bras, certaines répétées parfois jusqu’à 100 fois (108 exactement, nombre symbolique de l’infini).
              </p>
              <p>
                Le kundalini est un yoga précis, structuré, structurant et rapide. Certaines postures peuvent paraître difficiles,  car engageantes comme celle de la grenouille, une série de squats qui améliore la souplesse des hanches et des adducteurs, bétonne les cuisses et stimule la créativité. Cependant on ne vous demandera jamais d’aller au delà de vos capacités et justement de garder à l’esprit le respect de soi même.
              </p>
              <p>Pendant les cours, on peut prendre conscience de ses blocages physiques et émotionnels, de ses limites, c’est ainsi que la conscience s’affine ! Le respect de soi-même constitue le fil conducteur de l’apprentissage. Chaque posture peut s’adapter en fonction des capacités.</p>
              <p>Cette discipline permet au stress de s’évacuer et, pratiquée régulièrement, maintient tous les processus vitaux. On parle de kundalini, mais l’on pourrait tout aussi bien parler de chi ou de qi, c’est toujours de force vitale dont il s’agit. Au fil des séances, la colonne vertébrale s’assouplit, le dos se redresse et la poitrine s’ouvre. Le bien-être retrouvé devient visible.</p>
              <p>À l’instar des méditations, les kriyas peuvent être réalisés pendant le cours puis reproduits à la maison pendant 40, 90 ou 120 jours dans une démarche plus personnelle.
              </p>
            </div>
          </section>

          <section className="course-info">
            <h2>Calendrier des cours</h2>
            <div className="schedule">
              <CalendarPage />
              <div className='Prices'>
                <h2>Les tarifs</h2>
                <p>Année 2024/2025</p>
                  <ul>
                    <li><strong>Tarif de base des cours:</strong> 15€</li>
                    <li><strong>Tarif annuel:</strong> 305€ (possibilité de régler en 3x)</li>
                    <li><strong>Tarif trimestre 1:</strong> 125€ (14 cours)</li>
                    <li><strong>Tarif trimestre 2:</strong> 100€ (11 cours)</li>
                    <li><strong>Tarif trimestre 3:</strong> 80€ (10 cours)</li>
                    <li><strong>Carte de 10 cours:</strong> 125€</li>
                    <li><strong>Séance individuelle par visio:</strong> 15€</li>
                    <li><strong>Séance en ZOOM:</strong> 15€</li>
                    <li><strong>Cours en audio:</strong> 15€</li>
                  </ul>
                <a href="https://www.helloasso.com/associations/diwali-yoga" className="register-link">Voir les règlements via Hello Asso</a>
              </div>
            </div>
          </section>

          
          <section className="locations">
            <h2>Présentation des Lieux de Pratique</h2>
            <div className="location-item">

              <div className="salle-guenrouet">
                <h3>Salle de Guenrouët</h3>
                <p><strong>Anne-Laure:</strong> Guenrouët, Plessé, Redon</p>
              <div className="image-gallery">
                <img src={ImageSalleGuen1} alt="Salle Guenrouët 1" className="image-large"/>
                <img src={ImageSalleGuen2} alt="Salle Guenrouët 2" className="image-small"/>
                <img src={ImageSalleGuen3} alt="Salle Guenrouët 3" className="image-small"/>
              </div>
              </div>

              <div className="salle-breteil">
                <h3>Salle de Bréteil</h3>
                <p><strong>Carine:</strong> Breteil, le mardi de 18h30 à 20h (Salle 12)</p>
                <div className="image-gallery">
                  <img src={ImageSalleBreteil1} alt="Salle breteil 1" className="image-large"/>
                  <img src={ImageSalleBreteil2} alt="Salle breteil 2" className="image-small"/>
                  <img src={ImageSalleBreteil3} alt="Photo de carine" className="image-small"/>   
                </div>
              </div>

              <p><strong>Marie:</strong> Guenrouët, le mardi de 14h15 à 15h45</p>
            </div>

            <div className="location-item">
              <h3>Tahiti</h3>
              <p><strong>Aurélie:</strong> Papeete - Tahiti, le mercredi de 17h30 à 19h (Salle des Arpèges)</p>
            </div>
          </section>

         

          <section className="yoga-practice">
            <h2>Présentation du Yoga Kundalini</h2>
            <p>
              Le yoga Kundalini se distingue par ses postures précises, structurant et engageant le corps et l'esprit. Les effets sur le corps sont rapides et notables, comme l'amélioration de la souplesse de la colonne vertébrale et le renforcement musculaire. Chaque séance est thématique et minutée, avec des postures qui stimulent la conscience et l'énergie vitale.
            </p>
            <h3>Recommandations au préalable</h3>
            <ul>
              <li>Venir l’estomac vide et boire 2 à 3 grands verre d’eau avant la séance.</li>
              <li>Prendre une douche avant le cours si possible et attendre 2H après la pratique pour en reprendre une.</li>
              <li>Apporter une tenue souple en fibres naturelles, si possible de couleurs blanche, ou claire.</li>
              <li>Les dames, en période de règles ne forceront pas sur les exercices tels que le Sat Kriya, ou toute autre contraction du ventre ou du bas-ventre, ainsi que la respiration du feu.</li>
              <li>Ne forcez pas sur votre corps. Faites ce que vous pouvez dans les exercices, par rapport à vos propres possibilités. Par contre, vous pouvez un peu plus forcer dans les exercices de résistance qui ne touchent pas la structure du corps.</li>
              <li>Arrivez en avance pour vous relaxer et/ou vous échauffer avant le cours.</li>
              <li>Si vous vous sentez fatigué ou survolté après un cours, parlez en au professeur, Faites de même pour toute sensation ou troubles anormaux.</li>
              <li>Ne parlez pas pendant le cours, restez silencieux et intériorisé, conscient de votre corps et de votre respiration.</li>
              <li>Demandez toujours conseils à votre professeure pour une pratique chez vous, adaptée à votre demande particulière, et en rapport avec ce que vous recherchez dans la discipline.</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PratiquerLeYoga;