import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import BodyHome from "../components/BodyHome";
import Footer from "../components/Footer"

import '../css-styles/Home.scss';
import '../css-styles/Main.scss';
import '../css-styles/Responsive.scss';
import '../css-styles/Footer.scss';
import BackGround from "../components/BackGround";
import { Helmet } from 'react-helmet';




function Home() {
 

  return (
    <div className="home" >
      <div className="home-container">
        <Helmet>
          <title>Ma page d'accueil</title>
          <meta name="description" content="Association diwali yoga."></meta>
          <link rel="canonical" href="https://diwali-yoga.fr/" />
        </Helmet>
        <Header />
        <Hero/>
        <BodyHome/>
      </div>
        
        
        <Footer/>

    </div>
  );
}

export default Home;